import { Component, OnInit } from '@angular/core';

declare const $: any;
declare interface RouteInfo {
	path: string;
	title: string;
	icon: string;
	class: string;
}
export const ROUTES: RouteInfo[] = [
	//{ path: '/dashboard', title: 'Dashboard', icon: 'dashboard', class: '' },
	{ path: '/pages/learningResourcesGeneral', title: 'Learning Resources', icon: 'library_books', class: '' },
	{ path: '/pages/audioResourcesGeneral', title: 'Audio Resources', icon: 'volume_up', class: '' },
	{ path: '/pages/questionsGeneral', title: 'Questions', icon: 'contact_support', class: '' },
	{ path: '/pages/extracurricularQuestionsGeneral', title: 'Extracurricular Questions', icon: 'contact_support', class: '' },
	{ path: '/pages/gamificationValuesGeneral', title: 'Gamification Values', icon: 'videogame_asset', class: '' },
	{ path: '/pages/shopGeneral', title: 'Marketplace', icon: 'videogame_asset', class: '' },
	{ path: '/pages/rewardsGeneral', title: 'Level Up Rewards', icon: 'military_tech', class: '' },
	{ path: '/pages/userPermissionsGeneral', title: 'User Permissions', icon: 'local_police', class: '' },

	// { path: '/pages/accountsGeneral', title: 'Accounts',  icon:'person', class: '' },
	// { path: '/table-list', title: 'Table List',  icon:'content_paste', class: '' },
	// { path: '/typography', title: 'Typography',  icon:'library_books', class: '' },
	// { path: '/icons', title: 'Icons',  icon:'bubble_chart', class: '' },
	// { path: '/maps', title: 'Maps',  icon:'location_on', class: '' },
	// { path: '/notifications', title: 'Notifications',  icon:'notifications', class: '' },
	// { path: '/upgrade', title: 'Upgrade to PRO',  icon:'unarchive', class: 'active-pro' },
];

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.css']
})

export class SidebarComponent implements OnInit {
	menuItems: any[];

	constructor() { }

	ngOnInit() {
		this.menuItems = JSON.parse(localStorage.getItem("menuItems"));
		console.log(this.menuItems)
	}

	isMobileMenu() {
		if ($(window).width() > 991) {
			return false;
		}
		return true;
	};
}
