import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
window.addEventListener('beforeinstallprompt', event => {
  console.log(event);
  alert("beforeinstallprompt");
  this.promptEvent = event;
});

@Injectable({
  providedIn: 'root'
})
export class PwaService {

  promptEvent;

  constructor(private swUpdate: SwUpdate) {
    console.log("Constructor")
    // window.addEventListener('beforeinstallprompt', event => {
    //   console.log(event);
    //   alert("oa");
    //   this.promptEvent = event;
    // });

    // swUpdate.available.subscribe(event => {
    //   if (this.askUserToUpdate()) {
    //     window.location.reload();
    //   }
    // });
  }

  askUserToUpdate() {
    console.log("pwa-service")
    alert("pwa-service")

    return confirm('Want to update?')
  }
}
