export const environment = {
  production: false,
  baseURL: "http://localhost:3000/",
  login: "creator/users/login",//TODO:: change to users ??
  heartBeat: "heartbeat",
  getLearningResources: "learningResources/getAllLearningResources",
  getMoreResources: "learningResources/getMoreLearningResources",
  getLearningResource: "learningResources/",
  createLearningResource: "learningResources",
  updateLearningResource: "learningResources/",
  deleteLearningResource: "learningResources/",
  audioResources: 'audioResources/',
  getQuestion: "questions/",
  editQuestion: "questions/",
  addQuestion: "questions/",
  deleteQuestion: "questions/",
  addExtracurricularQuestion: "questions/addExtracurricularQuestion/",
  editExtracurricularQuestion: "questions/updateExtracurricularQuestion/",
  getExtracurricularQuestion: "questions/getExtracurricularQuestion/",
  deleteExtracurricularQuestion: "questions/deleteExtracurricularQuestion/",
  getAllChapters: "chapters/getAllChapters",
  getAllSubjects: "subjects/getAllSubjects",
  getExtracurricularSubjects: "getExtracurricularSubjects",
  getAllQuestions: "questions/getAllQuestions",
  getAllExtracurricularQuestions: "questions/getAllExtracurricularQuestions",
  getMoreExtracurricularQuestions: "questions/getMoreExtracurricularQuestions",
  getMoreQuestions: "questions/getMoreQuestions",
  getAllEntities: "entities/getAllEntities",
  firebaseConfig: {
    apiKey: "AIzaSyBB3zr0yI6LZJwJAPkgu2akq1SWo_87tow",
    authDomain: "izibac-9c408.firebaseapp.com",
    databaseURL: "https://izibac-9c408.firebaseio.com",
    projectId: "izibac-9c408",
    storageBucket: "izibac-9c408.appspot.com",
    messagingSenderId: "326834497169",
    appId: "1:326834497169:web:641024ec3a46f3e1f78130"
  },
  subjects: 'subjects/',
  getAllUsers: 'creator/users/all',
  getAllUsersWithPermissions: 'userPermission',
  marketplace: 'marketplace/',
  shop: 'shop/',
  rewards: 'creator/rewards/',
  searchUser: 'creator/users/search',
  getUserAccess: 'creator/users/getAccess/',
  boosters: 'boosters/',
  iziAdmin: 'iziadmin',
  creator: 'creator/',
  algoliaConfig: {
    appId: "X68DNJDP0K",
    apiKey: "dfd41348b16fa2e1c5e05d47f47f9ea2",
    searchKey: '',
    questionsINDEX: "questions_dev",
    extracurricularQuestionsINDEX: "extracurricular_questions_dev",
    learningResourcesINDEX: "learning_resources_dev"
  }
};

