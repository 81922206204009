import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';

declare const $: any;
declare interface PassingObject {
	item: {},
	modalBody: string
}

@Component({
	selector: 'app-warning-modal',
	templateUrl: './warning-modal.component.html',
	styleUrls: ['./warning-modal.component.css']
})
export class WarningModalComponent implements OnInit {

	@Input() onWarningClicked: Observable<PassingObject>;
	passingObject = {item: {}, modalBody: ''};
	@Output() onYesEvent: EventEmitter<any> = new EventEmitter();
	
	private eventsSubscription: any;
	itemForDeletion
	modalBody;

	constructor() { }

	ngOnInit() {
		this.eventsSubscription = this.onWarningClicked.subscribe(
			(passingObject) => {
				this.itemForDeletion = passingObject.item;
				this.modalBody = passingObject.modalBody;
				console.log('Warning modal', this.modalBody);
				$( "#modalToggle" ).click();
			})
	}

	ngOnDestroy() {
		this.eventsSubscription.unsubscribe()
	}

	onYesClick() {
		this.onYesEvent.next({delete: true, item: this.itemForDeletion});
		$( "#modalToggle" ).click();
	}

}
