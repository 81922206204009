import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AngularFireStorage } from "@angular/fire/storage";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class BackendServiceService {
  constructor(
    public _http: HttpClient,
    private firebaseStorage: AngularFireStorage,
    private spinner: NgxSpinnerService
  ) {}

  isUserAuthenticated() {
    return new Promise((resolve, reject) => {
      this._http
        .post(
          environment.baseURL + environment.heartBeat,
          localStorage.getItem("accessToken")
        )
        .subscribe(
          (resl) => {
            resolve(resl);
          },
          (rej) => {
            reject(rej);
          }
        );
    });
  }

  removeDashboard(menuItems: any) {
    //TODO:: this is temporary solution until backend merge
    var result = menuItems.filter(menuItem => menuItem.title == "Dashboard");
    if (result.length > 0) {
      let index = menuItems.indexOf(result[0]);
      menuItems.splice(index, 1);
    }
    return JSON.stringify(menuItems);
  }

  // login() {
  //   return new Promise((resolve, reject) => {
  //     this._http
  //       .post(environment.baseURL + environment.login, {})
  //       .subscribe(
  //         (resl: any) => {
  //           resolve(resl);
  //           let menuItems = this.removeDashboard(resl.data.available_sections);

  //           console.log('This is the new array: ' + menuItems);
  //           localStorage.setItem(
  //             "menuItems",
  //             menuItems
  //           );
  //         },
  //         (rej) => {
  //           reject(rej);
  //           console.log("Reject", rej);
  //         }
  //       );
  //   });
  // }

  login(body: any) {
    return new Promise((resolve, reject) => {
      const options = {headers: {'Content-Type': 'application/json'}};
      this._http
        .post(environment.baseURL + environment.login, body, options)
        .subscribe(
          (resl: any) => {
            resolve(resl);
            console.log(resl);
            let menuItems = resl.available_sections;//this.removeDashboard(resl.data.available_sections);
            console.log('This is the new array: ' + JSON.stringify(menuItems));
            localStorage.setItem(
              "menuItems",
              JSON.stringify(menuItems)
            );
            localStorage.setItem("user", JSON.stringify(resl.user));
            localStorage.setItem("accessToken", resl.token);
          },
          (rej) => {
            reject(rej);
            console.log("Reject", rej);
          }
        );
    });
  }

  // Upload Files
  uploadFiles(files, entityID, subjectID, question) {
    return new Promise((resolve, reject) => {
      function generateRandomID() {
        function s4() {
          return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
        }
        return (
          s4() +
          s4() +
          "-" +
          s4() +
          "-" +
          s4() +
          "-" +
          s4() +
          "-" +
          s4() +
          s4() +
          s4()
        );
      }

      if (files && files.length) {
        let uploadPromises = [];
        let pictureRefs = [];

        for (let file of files) {
          const randomID = generateRandomID();
          let filePath =
            "learningResources/" + entityID + "/" + subjectID + "/" + randomID;
          if (question) {
            filePath = "iziBacQuestionImages/" + randomID;
          }

          uploadPromises.push(this.firebaseStorage.upload(filePath, file));
          pictureRefs.push(filePath);
          // const fileRef = this.firebaseStorage.ref(entityBucket + randomID);
        }
        Promise.all(uploadPromises)
          .then((res) => {
            console.log(res);
            return resolve(pictureRefs);
          })
          .catch((err) => {
            console.log(err);
            return reject([]);
          });
      } else {
        return resolve([]);
      }
    });
  }

  //LearningResources
  getLearningResources() {
    // let paginationData = "";
    // if (offset || limit) {
    //   paginationData = "?offset=" + offset + "&limit=" + limit;
    // } else {
    //   paginationData = "?limit=9999";
    // }
    return new Promise((resolve, reject) => {
      this.spinner.show();
      this._http
        .get(environment.baseURL + environment.getLearningResources)
        .subscribe(
          (resl) => {
            this.spinner.hide();
            resolve(resl);
          },
          (rej) => {
            this.spinner.hide();
            reject(rej);
          }
        );
    });
  }

  search(index, query, page, limit) {
    // let paginationData = "";
    // if (offset || limit) {
    //   paginationData = "?offset=" + offset + "&limit=" + limit;
    // } else {
    //   paginationData = "?limit=9999";
    // }
    
    return new Promise((resolve, reject) => {
      this.spinner.show();
      this._http
        .get(environment.baseURL + `creator/search/${index}/${query}/${page}/${limit}`)
        .subscribe(
          (resl) => {
            this.spinner.hide();
            resolve(resl);
          },
          (rej) => {
            this.spinner.hide();
            reject(rej);
          }
        );
    });
  }

  getQuestionsCount(filteringColumn, filteringValue) {
    if(filteringColumn == "Enunt"){
      filteringColumn = "question";
    }
    if(filteringColumn == undefined || filteringColumn == null){
      filteringColumn = "";
    }
    if(filteringValue == undefined || filteringValue == null){
      filteringValue = "";
    }
    return new Promise((resolve, reject) => {
      this.spinner.show();
      this._http
        .get(environment.baseURL + `question/search/getQuestionCount?filteringColumn=${filteringColumn.toLowerCase()}&filteringValue=${filteringValue.toLowerCase()}`)
        .subscribe(
          (resl) => {
            this.spinner.hide();
            resolve(resl);
          },
          (rej) => {
            this.spinner.hide();
            reject(rej);
          }
        );
    });
  }

  
  searchQuestions(filteringColumn, filteringValue, sortBy, sortOrder, page, limit) {
    if(filteringColumn == "Enunt"){
      filteringColumn = "Question";
    }
    if(filteringColumn == undefined || filteringColumn == null){
      filteringColumn = "";
    }
    if(filteringValue == undefined || filteringValue == null){
      filteringValue = "";
    }
    if(sortBy == undefined || sortBy == null){
      sortBy = "";
    }

    return new Promise((resolve, reject) => {
      this.spinner.show();
      this._http
        .get(environment.baseURL + `question/search?filteringColumn=${filteringColumn.toLowerCase()}&filteringValue=${filteringValue.toLowerCase()}&sortBy=${sortBy.toLowerCase()}&sortOrder=${sortOrder}&limit=${limit}&page=${page}`)
        .subscribe(
          (resl) => {
            this.spinner.hide();
            resolve(resl);
          },
          (rej) => {
            this.spinner.hide();
            reject(rej);
          }
        );
    });
  }

  searchUser(mail) {
    // let paginationData = "";
    // if (offset || limit) {
    //   paginationData = "?offset=" + offset + "&limit=" + limit;
    // } else {
    //   paginationData = "?limit=9999";
    // }
    let data = {
      email: mail
    }
    
    return new Promise((resolve, reject) => {
      this.spinner.show();
      this._http
        .post(environment.baseURL + environment.searchUser, data)
        .subscribe(
          (resl) => {
            this.spinner.hide();
            resolve(resl);
          },
          (rej) => {
            this.spinner.hide();
            reject(rej);
          }
        );
    });
  }

  getMoreLearningResources(lastItemID?, limit?) {
    let paginationData = "";
    if (lastItemID || limit) {
      paginationData = "/" + lastItemID + "/" + limit;
    } else {
      paginationData = "?limit=9999";
    }
    return new Promise((resolve, reject) => {
      this.spinner.show();
      this._http
        .get(
          environment.baseURL + environment.getMoreResources + paginationData
        )
        .subscribe(
          (resl) => {
            this.spinner.hide();
            resolve(resl);
          },
          (rej) => {
            this.spinner.hide();
            reject(rej);
          }
        );
    });
  }

  getLearningResource(id) {
    return new Promise((resolve, reject) => {
      this.spinner.show();
      this._http
        .get(environment.baseURL + environment.getLearningResource + id)
        .subscribe(
          (resl) => {
            this.spinner.hide();
            resolve(resl);
          },
          (rej) => {
            this.spinner.hide();
            reject(rej);
          }
        );
    });
  }

  getAllChapters() {
    return new Promise((resolve, reject) => {
      this.spinner.show();
      this._http
        .get(environment.baseURL + environment.getAllChapters)
        .subscribe(
          (resl) => {
            this.spinner.hide();
            resolve(resl);
          },
          (rej) => {
            this.spinner.hide();
            reject(rej);
          }
        );
    });
  }

  getSubjects() {
    return new Promise((resolve, reject) => {
      this.spinner.show();
      this._http
        .get(environment.baseURL + environment.getAllSubjects)
        .subscribe(
          (resl) => {
            this.spinner.hide();
            resolve(resl);
          },
          (rej) => {
            this.spinner.hide();
            reject(rej);
          }
        );
    });
  }

  getExtracurricularSubjects() {
    return new Promise((resolve, reject) => {
      this.spinner.show();
      this._http
        .get(environment.baseURL + environment.subjects + environment.getExtracurricularSubjects)
        .subscribe(
          (resl) => {
            this.spinner.hide();
            resolve(resl);
          },
          (rej) => {
            this.spinner.hide();
            reject(rej);
          }
        );
    });
  }

  getQuestion(id) {
    return new Promise((resolve, reject) => {
      this.spinner.show();
      this._http
        .get(environment.baseURL + environment.getQuestion + id)
        .subscribe(
          (resl) => {
            this.spinner.hide();
            resolve(resl);
          },
          (rej) => {
            this.spinner.hide();
            reject(rej);
          }
        );
    });
  }

  getExtracurricularQuestion(id) {
    return new Promise((resolve, reject) => {
      this.spinner.show();
      this._http
        .get(environment.baseURL + environment.getExtracurricularQuestion + id)
        .subscribe(
          (resl) => {
            this.spinner.hide();
            resolve(resl);
          },
          (rej) => {
            this.spinner.hide();
            reject(rej);
          }
        );
    });
  }

  getAllQuestions() {
    return new Promise((resolve, reject) => {
      this.spinner.show();
      this._http
        .get(environment.baseURL + environment.getAllQuestions)
        .subscribe(
          (resl) => {
            this.spinner.hide();
            resolve(resl);
          },
          (rej) => {
            this.spinner.hide();
            reject(rej);
          }
        );
    });
  }

  getAllExtracurricularQuestions() {
    return new Promise((resolve, reject) => {
      this.spinner.show();
      this._http
        .get(environment.baseURL + environment.getAllExtracurricularQuestions)
        .subscribe(
          (resl) => {
            this.spinner.hide();
            resolve(resl);
          },
          (rej) => {
            this.spinner.hide();
            reject(rej);
          }
        );
    });
  }

  getMoreQuestions(questionID, limit) {
    return new Promise((resolve, reject) => {
      this.spinner.show();
      this._http
        .get(
          environment.baseURL +
            environment.getMoreQuestions +
            `/${questionID}/${limit}`
        )
        .subscribe(
          (resl) => {
            this.spinner.hide();
            resolve(resl);
          },
          (rej) => {
            this.spinner.hide();
            reject(rej);
          }
        );
    });
  }

  getMoreExtracurricularQuestions(questionID, limit) {
    return new Promise((resolve, reject) => {
      this.spinner.show();
      this._http
        .get(
          environment.baseURL +
            environment.getMoreExtracurricularQuestions +
            `/${questionID}/${limit}`
        )
        .subscribe(
          (resl) => {
            this.spinner.hide();
            resolve(resl);
          },
          (rej) => {
            this.spinner.hide();
            reject(rej);
          }
        );
    });
  }

  createQuestion(questionObject) {
    return new Promise((resolve, reject) => {
      this.spinner.show();
      console.log(questionObject);
      this.uploadFiles(questionObject.images, null, null, true)
        .then((pictureRefs) => {
          questionObject.images = pictureRefs;
          this.uploadFiles(
            questionObject.explanationImages,
            null,
            null,
            true
          ).then((explanationRefs) => {
            questionObject.explanationImages = explanationRefs;
            console.log(questionObject);
            this._http
              .post(
                environment.baseURL + environment.addQuestion,
                questionObject
              )
              .subscribe(
                (resl) => {
                  this.spinner.hide();
                  resolve(resl);
                },
                (rej) => {
                  this.spinner.hide();
                  reject(rej);
                }
              );
          });
        })
        .catch((err) => {
          this.spinner.hide();
          return reject(err);
        });
    });
  }

  createExtracurricularQuestion(questionObject) {
    return new Promise((resolve, reject) => {
      this.spinner.show();
      console.log(questionObject);
      this.uploadFiles(
        questionObject.images,
        questionObject.entityID,
        questionObject.subjectID,
        false
      )
        .then((pictureRefs) => {
          questionObject.images = pictureRefs;
          this.uploadFiles(
            questionObject.explanationImages,
            null,
            null,
            true
          ).then((explanationRefs) => {
            questionObject.explanationImages = explanationRefs;
            console.log(questionObject);
            this._http
              .post(
                environment.baseURL + environment.addExtracurricularQuestion,
                questionObject
              )
              .subscribe(
                (resl) => {
                  this.spinner.hide();
                  resolve(resl);
                },
                (rej) => {
                  this.spinner.hide();
                  reject(rej);
                }
              );
          });
        })
        .catch((err) => {
          this.spinner.hide();
          return reject(err);
        });
    });
  }

  editQuestion(questionObject) {
    let files = [];

    function moveFiles(src, dest) {
      var i = 0;
      while (i < src.length) {
        var item = src[i];
        if (typeof item !== "string") {
          src.splice(i, 1);
          dest.push(item);
        } else i++;
      }
    }

    moveFiles(questionObject.images, files);

    return new Promise((resolve, reject) => {
      this.spinner.show();
      this.uploadFiles(files, null, null, true)
        .then((pictureRefs) => {
          files = [];
          questionObject.images = questionObject.images.concat(pictureRefs);
          moveFiles(questionObject.explanationImages, files);
          this.uploadFiles(files, null, null, true).then((explanationRefs) => {
            questionObject.explanationImages = questionObject.explanationImages.concat(
              explanationRefs
            );
            console.log(questionObject);
            this._http
              .put(
                environment.baseURL +
                  environment.editQuestion +
                  questionObject.id,
                questionObject
              )
              .subscribe(
                (resl) => {
                  this.spinner.hide();
                  resolve(resl);
                },
                (rej) => {
                  this.spinner.hide();
                  reject(rej);
                }
              );
          });
        })
        .catch((err) => {
          this.spinner.hide();
          return reject(err);
        });
    });
  }

  editExtracurricularQuestion(questionObject) {
    let files = [];

    function moveFiles(src, dest) {
      var i = 0;
      while (i < src.length) {
        var item = src[i];
        if (typeof item !== "string") {
          src.splice(i, 1);
          dest.push(item);
        } else i++;
      }
    }

    moveFiles(questionObject.images, files);

    return new Promise((resolve, reject) => {
      this.spinner.show();
      this.uploadFiles(
        files,
        questionObject.entityID,
        questionObject.subjectID,
        false
      )
        .then((pictureRefs) => {
          files = [];
          questionObject.images = questionObject.images.concat(pictureRefs);
          moveFiles(questionObject.explanationImages, files);
          this.uploadFiles(
            files,
            questionObject.entityID,
            questionObject.subjectID,
            false
          ).then((explanationRefs) => {
            questionObject.explanationImages = questionObject.explanationImages.concat(
              explanationRefs
            );
            console.log(questionObject);
            this._http
              .put(
                environment.baseURL +
                  environment.editExtracurricularQuestion +
                  questionObject.id,
                questionObject
              )
              .subscribe(
                (resl) => {
                  this.spinner.hide();
                  resolve(resl);
                },
                (rej) => {
                  this.spinner.hide();
                  reject(rej);
                }
              );
          });
        })
        .catch((err) => {
          this.spinner.hide();
          return reject(err);
        });
    });
  }

  deleteQuestion(id) {
    return new Promise((resolve, reject) => {
      this.spinner.show();
      this._http
        .delete(environment.baseURL + environment.deleteQuestion + id)
        .subscribe(
          (resl) => {
            this.spinner.hide();
            resolve(resl);
          },
          (rej) => {
            this.spinner.hide();
            reject(rej);
          }
        );
    });
  }

  deleteExtracurricularQuestion(id) {
    return new Promise((resolve, reject) => {
      this.spinner.show();
      this._http
        .delete(
          environment.baseURL + environment.deleteExtracurricularQuestion + id
        )
        .subscribe(
          (resl) => {
            this.spinner.hide();
            resolve(resl);
          },
          (rej) => {
            this.spinner.hide();
            reject(rej);
          }
        );
    });
  }

  createLearningResource(learningResourceObject) {
    return new Promise((resolve, reject) => {
      this.spinner.show();
      this.uploadFiles(
        learningResourceObject.references,
        learningResourceObject.entityID,
        learningResourceObject.subjectID,
        false
      )
        .then((pictureRefs) => {
          learningResourceObject.references = pictureRefs;

          this._http
            .post(
              environment.baseURL + environment.createLearningResource,
              learningResourceObject
            )
            .subscribe(
              (resl) => {
                this.spinner.hide();
                resolve(resl);
              },
              (rej) => {
                this.spinner.hide();
                reject(rej);
              }
            );
        })
        .catch((err) => {
          this.spinner.hide();
          return reject(err);
        });
    });
  }

  updateGamificationValues(newValues) {
    return new Promise((resolve, reject) => {
      this.spinner.show();
      this._http
        .put(
          environment.baseURL + environment.iziAdmin + '/updateGamificationValues/' + newValues.id,
          newValues
        )
        .subscribe(
          (resl) => {
            this.spinner.hide();
            resolve(resl);
          },
          (rej) => {
            this.spinner.hide();
            reject(rej);
          }
        );
    });
  }

  editLearningResource(learningResourceObject) {
    delete learningResourceObject.documents;

    let files = [];

    function moveFiles(src, dest) {
      var i = 0;
      while (i < src.length) {
        var item = src[i];
        if (typeof item !== "string") {
          src.splice(i, 1);
          dest.push(item);
        } else i++;
      }
    }

    moveFiles(learningResourceObject.references, files);

    return new Promise((resolve, reject) => {
      this.spinner.show();
      this.uploadFiles(
        files,
        learningResourceObject.entityID,
        learningResourceObject.subjectID,
        false
      )
        .then((pictureRefs) => {
          learningResourceObject.references = learningResourceObject.references.concat(
            pictureRefs
          );
          this._http
            .put(
              environment.baseURL +
                environment.updateLearningResource +
                learningResourceObject.id,
              learningResourceObject
            )
            .subscribe(
              (resl) => {
                this.spinner.hide();
                resolve(resl);
              },
              (rej) => {
                this.spinner.hide();
                reject(rej);
              }
            );
        })
        .catch((err) => {
          this.spinner.hide();
          return reject(err);
        });
    });
  }

  deleteLearningResource(id) {
    return new Promise((resolve, reject) => {
      this.spinner.show();
      this._http
        .delete(environment.baseURL + environment.deleteLearningResource + id)
        .subscribe(
          (resl) => {
            this.spinner.hide();
            resolve(resl);
          },
          (rej) => {
            this.spinner.hide();
            reject(rej);
          }
        );
    });
  }

  //Entities
  getAllEntities(offset?, limit?) {
    let paginationData = "";
    if (offset || limit) {
      paginationData = "?offset=" + offset + "&limit=" + limit;
    } else {
      paginationData = "?limit=9999";
    }
    return new Promise((resolve, reject) => {
      this.spinner.show();
      this._http
        .get(environment.baseURL + environment.getAllEntities + paginationData)
        .subscribe(
          (resl) => {
            this.spinner.hide();
            resolve(resl);
          },
          (rej) => {
            this.spinner.hide();
            reject(rej);
          }
        );
    });
  }

  getAllGamificationValues() {
    return new Promise((resolve, reject) => {
      this.spinner.show();
      this._http
        .get(
          environment.baseURL +
            environment.iziAdmin +
            `/getGlobalValuesForAllEntities`
        )
        .subscribe(
          (resl) => {
            this.spinner.hide();
            resolve(resl);
          },
          (rej) => {
            this.spinner.hide();
            reject(rej);
          }
        );
    });
  }

  getGamificationValuesForEntity(entityID) {
    return new Promise((resolve, reject) => {
      this.spinner.show();
      this._http
        .get(
          environment.baseURL +
            environment.iziAdmin +
            `/getGlobalValuesByEntity/` +
            entityID
        )
        .subscribe(
          (resl) => {
            this.spinner.hide();
            resolve(resl);
          },
          (rej) => {
            this.spinner.hide();
            reject(rej);
          }
        );
    });
  }
}
