import { Injectable, NgModule } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable({
	providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

	constructor(private _router: Router) {
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let changedRequest = req;
		const token = localStorage.getItem('accessToken');

		// changedRequest = req.clone({ 
		// 	headers: req.headers
		// 	.set("content-type", "application/json")
		// 	.set("accept", "application/json")
		// 	.set("Accept-Encoding", "gzip")
		// 	.set("Cache-Control", "no-cache")
		// 	.set('Access-Control-Allow-Credentials', 'true')
		// 	.set('Access-Control-Allow-Origin','*')
		// 	.set('Access-Control-Allow-Methods','GET')
		// 	.set('Access-Control-Allow-Headers','application/json')
		// });

		// if (token) {
		// 	changedRequest = req.clone({
		// 		headers: req.headers.set("access-token", token)
		// 	});
		// 	console.log(changedRequest);
		// }
		// return next.handle(changedRequest);


		// HttpHeader object immutable - copy values
		const headerSettings: { [name: string]: string | string[]; } = {};

		for (const key of req.headers.keys()) {
			headerSettings[key] = req.headers.getAll(key);
		}
		if (token) {
			headerSettings['Authorization'] = 'Bearer ' + token;
		}
		headerSettings['Content-Type'] = 'application/json';
		const newHeader = new HttpHeaders(headerSettings);

		changedRequest = req.clone({
			headers: newHeader
		});

		return next.handle(changedRequest)
			.pipe(tap(() => { },
				(err: any) => {
					if (err instanceof HttpErrorResponse) {
						switch (err.status){
							case 500 : {
								this._router.navigate(['/login']);
								return;
							}
							case 401: {
								this._router.navigate(['/login']);
								return;
							}
							default: {
								return;
							}
						}
					}
				})
			);
	}
};


@NgModule({
	providers: [{
		provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true
	}]
})
export class InterceptorModule { }