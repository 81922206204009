import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthGuardService } from './guards/auth-guard.service';
import { LoginComponent } from './pages/login/login.component';

const routes: Routes = [
	{
		path: '',
		redirectTo: 'login',
		pathMatch: 'full',
	}, {
		path: '',
		component: AdminLayoutComponent,
		children: [
			{
				path: '',
				loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
			},
			{
				path: 'pages',
				loadChildren: './pages/pages.module#PagesModule',
				// canActivate: [AuthGuardService],
			},
		]
	},
	{
		path: 'login',
		component: LoginComponent,
		// canActivate: [AuthGuardService],
	},
];

@NgModule({
	imports: [
		CommonModule,
		BrowserModule,
		RouterModule.forRoot(routes, {
			useHash: true
		})
	],
	exports: [
	],
})
export class AppRoutingModule { }
