import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "app-html-edit",
  templateUrl: "./html-edit.component.html",
  styleUrls: ["./html-edit.component.scss"],
})
export class HtmlEditComponent implements OnInit {
  config = {
    uiColor: "#ffffff",
    toolbarGroups: [
      { name: "clipboard", groups: ["clipboard", "undo"] },
      { name: "editing", groups: ["find", "selection", "spellchecker"] },
      { name: "links" },
      { name: "insert" },
      { name: "document", groups: ["mode", "document", "doctools"] },
      { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
      { name: "paragraph", groups: ["list", "indent", "blocks", "align"] },
      { name: "styles" },
      { name: "colors" },
    ],
    resize_enabled: true,
    removePlugins: "elementspath,save,magicline",
    extraPlugins: "mathjax",
    colorButton_foreStyle: {
      element: "font",
      attributes: { color: "#(color)" },
    },
    height: 188,
    removeDialogTabs: "image:advanced;link:advanced",
    removeButtons: "Subscript,Superscript,Anchor,Source,Table",
    format_tags: "p;h1;h2;h3;pre;div",
    mathJaxLib:
      "//cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/MathJax.js?config=TeX-AMS_HTML",
  };

  constructor(
    public dialogRef: MatDialogRef<HtmlEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
  }

  ngOnDestroy() {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
  }

  ngAfterViewInit() {}

  onSave(){
    this.dialogRef.close(Object.assign({}, this.data))
  }
}
