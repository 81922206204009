import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { WarningModalComponent } from "./warning-modal/warning-modal.component";
import { CKEditorModule } from "ckeditor4-angular";
import { HtmlEditComponent } from "./html-edit/html-edit.component";
import { FormModalComponent } from "./form-modal/form-modal.component";
import { AddUsersModalComponent } from "./add-users-modal/add-users-modal.component";
@NgModule({
  imports: [FormsModule, ReactiveFormsModule, CommonModule, RouterModule, FormsModule, CKEditorModule],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    WarningModalComponent,
    SidebarComponent,
    AddUsersModalComponent,
    HtmlEditComponent
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    WarningModalComponent,
    AddUsersModalComponent,
    HtmlEditComponent
  ],
  entryComponents: [HtmlEditComponent,AddUsersModalComponent]
})
export class ComponentsModule {}
