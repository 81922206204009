import { Component, HostListener } from '@angular/core';
import { PwaService } from './services/pwa-service/pwa-service.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent {
	constructor(public Pwa: PwaService) { }

	deferredPrompt: any;
	showButton = false;

	@HostListener('window:beforeinstallprompt', ['$event'])
	onbeforeinstallprompt(e) {
		console.log("triggered beforeinstallprompt")
		console.log(e);
		// Prevent Chrome 67 and earlier from automatically showing the prompt
		e.preventDefault();
		// Stash the event so it can be triggered later.
		this.deferredPrompt = e;
		this.showButton = true;
	}


	addToHomeScreen() {
		console.log("Add to homescreen");
		// hide our user interface that shows our A2HS button
		this.showButton = false;
		// Show the prompt
		this.deferredPrompt.prompt();
		// Wait for the user to respond to the prompt
		this.deferredPrompt.userChoice
			.then((choiceResult) => {
				if (choiceResult.outcome === 'accepted') {
					console.log('User accepted the A2HS prompt');
				} else {
					console.log('User dismissed the A2HS prompt');
				}
				this.deferredPrompt = null;
			});
	}

	installPwa(): void {
		alert("Installing PWA");
		this.Pwa.promptEvent.prompt();
		// this.Pwa.askUserToUpdate();
	}

}
