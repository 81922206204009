import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { auth } from "firebase/app";
import { AngularFireAuth } from "@angular/fire/auth";
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from "@angular/fire/firestore";

import { Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  user$: Observable<any>;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router
  ) {
    // Get the auth state, then fetch the Firestore user document or return null
    this.user$ = this.afAuth.authState.pipe(
      switchMap((user) => {
        // Logged in
        if (user) {
          return this.afs.doc(`users/${user.uid}`).valueChanges();
        } else {
          // Logged out
          return of(null);
        }
      })
    );
  }

  async userPassLogin(email, password) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password);
  }

  async signInWithCustomToken(token) {
    return this.afAuth.auth.signInWithCustomToken(token);
  } 

  getUserClaims() {
	  return new Promise((resolve, reject) => {
		this.user$.pipe(
			map((user) => {
			  if (user) {
				user.getIdTokenResult().then((idTokenResult) => {
				  console.log(idTokenResult);
				  resolve(idTokenResult);
				});
			  } else {
				  resolve(false)
			  }
			})
		  );
	  })
  }

  // async googleSignin() {
  // 	const provider = new auth.GoogleAuthProvider();
  // 	let authData = await this.afAuth.auth.signInWithPopup(provider);
  // 	// const newCredential = await this.afAuth.GoogleAuthProvider.credential(null, token);
  // 	return this.afAuth.auth.signInWithCredential(authData.credential);
  // }

  async checkIfUserIsAdmin() {
    //
    // Find hee how to perform the check
    // https://firebase.google.com/docs/auth/admin/custom-claims
    //
  }

  private updateUserData(user) {
    // Sets user data to firestore on login
    const userRef: AngularFirestoreDocument = this.afs.doc(`users/${user.uid}`);

    // const data = {
    // 	uid: user.uid,
    // 	email: user.email,
    // 	displayName: user.displayName,
    // 	photoURL: user.photoURL
    // }

    // return userRef.set(data, { merge: true })
    return true;
  }

  async signOut() {
    await this.afAuth.auth.signOut();
    this.router.navigate(["/"]);
  }
}
