import { Component, OnInit, AfterViewInit } from "@angular/core";
import { BackendServiceService } from "../../services/backend-service.service";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { AuthService } from "./../../services/auth/auth.service";
import swal from "sweetalert2";
import { NgxSpinnerService } from "ngx-spinner";

declare interface DataTable {
  headerRow: string[];
  footerRow: string[];
  dataRows?: string[][];
}

declare const $: any;

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  public dataTableContent: DataTable;
  deleteClicked: Subject<void> = new Subject<void>();
  deletionObject;
  totalItems = 300;

  email = "";
  password = "";
  error;

  constructor(
    private backend: BackendServiceService,
    private authService: AuthService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private backendService: BackendServiceService
  ) {}

  ngOnInit() {}

  // userPassLogin() {
  //   this.spinner.show();
  //   this.authService
  //     .userPassLogin(this.email, this.password)
  //     .then((res) => {
  //       res.user.getIdTokenResult(/* forceRefresh */ true).then((idToken) => {
  //         localStorage.setItem("user", JSON.stringify(idToken));
  //         localStorage.setItem("accessToken", idToken.token);
  //         this.backendService.login().then((resp: any) => {
  //           this.authService
  //             .signInWithCustomToken(resp.data.token)
  //             .then((res) => {
  //               res.user
  //                 .getIdTokenResult(/* forceRefresh */ true)
  //                 .then((idToken) => {
  //                   localStorage.setItem("user", JSON.stringify(idToken));
  //                   localStorage.setItem("accessToken", idToken.token);
  //                   console.log(idToken);
  //                   this.spinner.hide();
  //                   this.router.navigate(["pages/learningResourcesGeneral"]);
  //                 });
  //             });
  //         });
  //       });
  //     })
  //     .catch((err) => {
  //       this.error = err.message;
  //       console.log(err);
  //       this.spinner.hide();
  //     });
  // }

  userPassLogin() {
    this.spinner.show();
    this.backendService.login({email: this.email,password: this.password}).then((resp: any) => {
      this.spinner.hide();
      this.router.navigate(["dashboard"]);
    }) .catch((err) => {
      this.error = err.message;
      console.log(err);
      this.spinner.hide();
    });
  }

  // googleLogin() {
  //     this.authService.googleSignin()
  //         .then((res) => {
  //             localStorage.setItem('accessToken', res.user['ma']);
  //             this.router.navigate(['dashboard']);
  //         })
  //         .catch((err) => {
  //             console.log(err);
  //         });
  // }
}
