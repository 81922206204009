import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { BackendServiceService } from "app/services/backend-service.service";

@Component({
  selector: "app-add-users-modal",
  templateUrl: "./add-users-modal.component.html",
  styleUrls: ["./add-users-modal.component.scss"],
})
export class AddUsersModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AddUsersModalComponent>,
    private backend: BackendServiceService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  searchFormControl = new FormControl("", [Validators.required]);

  formInputsObject = [
    // {
    // 	type: 'string',
    // 	placeholder: 'Name',
    // 	key: 'name',
    // 	label: 'Name',
    // },
    // {
    // 	label: 'Access level',
    // 	key: 'access_level',
    // 	type: 'dropdown',
    // 	options: this.accessLevels
    // },
  ];

  userList = [];

  formErrors = {};
  formModel = {};
  user = {
    loginData: { email: "", nickname: "" },
    pushInfos: { notificationToken: "" },
  };
  userFound: boolean = false;
  ngOnInit() {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    // this.generateInputForm();
    this.userList = this.data.formModel;
    console.log(this.data);
  }

  generateInputForm() {
    console.log(this.data);
    console.log("==>>", this.data.formElement);
    this.data.formElement.formInputObject.map((el) => {
      this.formInputsObject.push(el);
      if (this.data.formModel) {
        this.formModel[el.key] = this.data.formModel[el.key];
      }
    });
  }

  onSearch() {
    this.backend.searchUser(this.searchFormControl.value).then(
      (resl: any) => {
        // console.log('LearningResource successfully deleted', resl);
        console.log(resl);
        console.log("Success", JSON.parse(JSON.stringify(resl)));
        if (Object.keys(resl.data).length > 0) {
          console.log(resl.data);
          this.user = resl.data;
          this.userFound = true;
        } else {
          this.userFound = false;
        }
      },
      (rej) => {
        // console.log('LearningResource was not deleted', rej);
        console.log(rej);
      }
    );
  }

  addUser() {
    if (this.user.pushInfos) {
      if (this.user.pushInfos.notificationToken.length > 0) {
        this.userList.push(this.user);
        // this.dialogRef.close(Object.assign({}, this.userList));
      } else {
        alert("User does not have a notification token saved");
      }
    } else {
      alert("User does not have a notification token saved");
    }
  }

  ngOnDestroy() {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
  }

  ngAfterViewInit() {}

  onSave() {
    let userTokens = [];
    this.userList.forEach((user) => {
      userTokens.push(user.pushInfos.notificationToken);
    });
    this.dialogRef.close(this.userList);
  }
}
